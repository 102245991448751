//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ScrollWrapperV2 from '@/components/v2/hoc/ScrollWrapperV2.vue'

export default {
  name: 'LongTermLearning',
  components: {
    ScrollWrapperV2,
  },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      arrowButton: require('@/assets/img/v2/arrowButton.svg'),
      cardAutoSwitchOld: [
        require('@/assets/img/v2/learning/learnGif1.gif'),
        require('@/assets/img/v2/learning/learnGif2.gif'),
        require('@/assets/img/v2/learning/learnGif3.gif'),
        require('@/assets/img/v2/learning/learnGif4.gif'),
      ],
      currentCardIndex: 0,
      autoSwitchIndex: 0,
      previousAutoSwitchIndex: -1,
      isDesktop: false,
    }
  },
  computed: {
    currentCard() {
      return this.detailData.data.teachData[this.currentCardIndex]
    },
    cardAutoSwitch() {
      return this.detailData.data.teachData
        .filter((item) => item.logo[0]?.url !== undefined)
        .map((item) => item.logo[0].url)
    },
    cardAutoSwitchCard() {
      return this.cardAutoSwitch[this.autoSwitchIndex]
    },
  },
  mounted() {
    setInterval(this.autoSwitchCards, 5000)
    this.checkWidth()
    window.addEventListener('resize', this.checkWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWidth)
  },
  methods: {
    checkWidth() {
      this.isDesktop = window.innerWidth > 768
    },
    nextCard() {
      this.currentCardIndex =
        (this.currentCardIndex + 1) % this.detailData.data.teachData.length
    },
    prevCard() {
      this.currentCardIndex =
        (this.currentCardIndex - 1 + this.detailData.data.teachData.length) %
        this.detailData.data.teachData.length
    },
    autoSwitchCards() {
      this.previousAutoSwitchIndex = this.autoSwitchIndex
      this.autoSwitchIndex =
        (this.autoSwitchIndex + 1) % this.cardAutoSwitch.length
    }, // cardAutoSwitch отвечает за то по какому списку надо проходить
  },
}
