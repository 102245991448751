//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CtaButton from '@/components/v2/button/CtaButton.vue'
import InfoBox from '@/components/v2/button/InfoBox.vue'
export default {
  name: 'FirstSteps',
  components: {
    CtaButton,
    InfoBox,
  },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      pathImgDetail: '',
      firstSteps2: require('@/assets/img/v2/firstSteps2.png'),
    }
  },
  computed: {
    borderAngleClass() {
      return {
        'border-angle': true,
        'border-angle--dynamic': true,
      }
    },
    borderAngleStyle() {
      const imagePath = require(`@/assets/img/v2/detail/${this.detailData.routePath}/spanBorder.svg`)
      return {
        '--image-span-angle-url': `url(${imagePath})`,
      }
    },
    realData() {
      return this.detailData.isDetail
        ? this.detailData.data.dataC2
        : this.detailData.data.dataIndex
    },
  },
  watch: {
    detailData() {
      this.updatePathImgDetail()
    },
  },
  // после того, как компонент был установлен на DOM
  mounted() {
    this.updatePathImgDetail()
    const wrapper = this?.$refs?.infoWrapper
    let isDown = false
    let startX
    let scrollLeft

    wrapper?.addEventListener('mousedown', (e) => {
      isDown = true
      wrapper.classList.add('active')
      startX = e.pageX - wrapper.offsetLeft
      scrollLeft = wrapper.scrollLeft
    })

    wrapper?.addEventListener('mouseleave', () => {
      isDown = false
      wrapper.classList.remove('active')
    })

    wrapper?.addEventListener('mouseup', () => {
      isDown = false
      wrapper.classList.remove('active')
    })

    wrapper?.addEventListener('mousemove', (e) => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - wrapper.offsetLeft
      const walk = (x - startX) * 3 // Скорость прокрутки
      wrapper.scrollLeft = scrollLeft - walk
    })
  },

  methods: {
    updatePathImgDetail() {
      const { routePath, viewportWidth } = this.detailData

      if (!routePath || routePath === 'index') {
        this.pathImgDetail = this.firstSteps2
      } else if (viewportWidth <= 768) {
        this.pathImgDetail = require(`@/assets/img/v2/detail/${routePath}/logoPagePhone.png`)
      } else {
        this.pathImgDetail = require(`@/assets/img/v2/detail/${routePath}/logoPage.png`)
      }
    },
  },
}
