//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FormatOfClasses',
  components: {},
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: Number,
        data: {},
      }),
    },
  },
  data() {
    return {}
  },
  computed: {
    borderTriangleClass() {
      return {
        'border-triangle': true,
        'border-triangle--dynamic': true,
      }
    },
    borderTriangleStyle() {
      const imagePath = require(`@/assets/img/v2/detail/${this.detailData.routePath}/7.svg`)
      return {
        '--image-url': `url(${imagePath})`,
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {},
}
