var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasePageWrapper',{ref:"basePageWrapper",attrs:{"page-name":"main","page-key":"main","is-detail-page":_vm.isDetailPage,"component-order":[
    'HeaderMain',
    'FirstSteps',
    'TrustSection',
    'ItGoals',
    'LongTermLearning',
    'Platform',
    'ApplicationForm',
    'BtwSection',
    'Teachers',
    'TestimonialsCustomers',
    'MapAddresses',
    'FAQ',
    'FooterMain' ]}})}
var staticRenderFns = []

export { render, staticRenderFns }