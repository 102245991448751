//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

import IndexNew from '@/components/page/IndexNew.vue'
import IndexOld from '@/components/page/IndexOld.vue'
export default {
  name: 'Base',
  layout: ({ store }) => {
    return store.getters['landings/textSetting']('template_version') === 2
      ? 'default'
      : 'partner'
  },
  components: { IndexNew, IndexOld },
  computed: {
    ...mapGetters('landings', ['featureEnabled']),
  },
}
