//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SelectYourArea',
  props: {
    isShowPopUp: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      detail: {
        header: 'Выберите ваш район',
        sity: [
          { name: 'Академический район', href: '#ak' },
          { name: 'Верх-Исетский район', href: '#vi' },
          { name: 'Железнодорожный район', href: '#scheR' },
          { name: 'Орджаникедзевский район', href: '#or' },
          { name: 'Кировский район', href: '#kr' },
          { name: 'Кировский 1', href: '#k1' },
          { name: 'Кировский 2', href: '#k2' },
          { name: 'Кировский 3', href: '#k3' },
          { name: 'Кировский 4', href: '#k4' },
          { name: 'Кировский 5', href: '#k5' },
          { name: 'Кировский 6', href: '#k6' },
        ],
      },
    }
  },
  methods: {
    closePop() {
      this.$emit('closePopUp')
    },
  },
}
