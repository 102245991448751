//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
import DropdownArrow from '@/components/v2/span/DropdownArrow.vue'
export default {
  name: 'LanguageDropdown',
  components: {
    DropdownArrow,
  },
  props: {},
  data() {
    return {
      dropdownActive: false,
    }
  },
  computed: {
    ...mapGetters({
      availableLanguageCodes: 'landings/availableLanguageCodes',
      currentLanguageCode: 'landings/currentLanguageCode',
    }),
    ...mapState(['landings', 'i18n']),
    currentDomainData() {
      return this.$getCurrentDomainData()
    },
  },
  watch: {},
  mounted() {},
  methods: {
    toggleDropdown() {
      if (this.dropdownActive === true) {
        this.dropdownActive = false
      } else {
        this.dropdownActive = true
      }
    },
    activeClass(languageCode) {
      return this.isCurrentLanguage(languageCode)
        ? '_active disable-lang-chose'
        : ''
    },
    isCurrentLanguage(languageCode) {
      return languageCode === this.currentLanguageCode.substr(0, 2)
    },
    selectNewEl(el) {
      this.toggleDropdown()
    },
    switchLanguage(languageCode) {
      if (this.isCurrentLanguage(languageCode)) {
        return
      }
      window.location.href = this.switchLocalePath(languageCode)
    },
  },
}
