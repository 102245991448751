//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DetailsInfo from '@/components/v2/pop-up/DetailsInfo.vue'
import {
  requestSuccessStore,
  requestSuccessMethods,
} from '~/plugins/requestSuccess.js'
export default {
  name: 'RequestSuccess',
  components: {
    DetailsInfo,
  },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      // isPopUpVisible: false,
    }
  },
  computed: {
    isPopUpVisible() {
      // return this.$store.getters['requestSuccess/isPopUpVisible']
      return requestSuccessStore.isVisible
    },

    popUp() {
      return !!this.detailData.data.btn
    },
  },
  watch: {},
  mounted() {},
  methods: {
    handleClick() {
      // this.$store.dispatch('requestSuccess/hidePopUp')
      requestSuccessMethods.hidePopUp()
    },
  },
}
