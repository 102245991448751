//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CardWhatWeOffer from '@/components/v2/card/CardWhatWeOffer.vue'
export default {
  name: 'WhatWeOffer',
  components: {
    CardWhatWeOffer,
  },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      backgroundImageLine: require(`@/assets/img/v2/detail/${this.detailData.routePath}/line.svg`),
    }
  },
  watch: {},
  mounted() {},
  methods: {},
}
