//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CardCause',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    svgVisibility: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      star: require('@/assets/img/v2/star.svg'),
    }
  },
}
