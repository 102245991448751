import { newMasks } from '~/const/masks.js'
import colors from '~/const/colorPath.js'

export default {
  data() {
    return {
      localesObject: [],
      routePath: '', // Инициализация переменной
      baseColor: '#fff',
    }
  },
  created() {
    // Присвоение значения route в хуке created
    this.updateRoute()
  },
  watch: {
    // Наблюдатель за изменением маршрута
    '$route.path'() {
      this.updateRoute()
    },
    routePath() {
      this.updateBaseColor()
    },
  },
  mounted() {
    this.updateBaseColor()
  },
  methods: {
    updateRoute() {
      // Получаем список локалей из newMasks
      this.localesObject = Object.keys(newMasks)
      // en нету по какойто причине
      this.localesObject.push('en')
      // Создаем строку локалей для регулярного выражения
      const listPais = this.localesObject.join('|')
      // Создаем регулярное выражение для удаления локали из пути
      const localePattern = new RegExp(`^\\/(${listPais})(\\/|$)`, 'i')

      // Убираем локаль из пути
      let pathWithoutLocale = this.$route.path.replace(localePattern, '')

      // Если путь начинается с "/", удаляем его
      if (pathWithoutLocale.startsWith('/')) {
        pathWithoutLocale = pathWithoutLocale.slice(1)
      }
      // Получаем сегменты пути
      const segments = pathWithoutLocale.split('/').filter(Boolean)
      // Если нет сегментов, возвращаем "index"
      if (segments.length === 0) {
        this.routePath = 'index'
      } else if (segments.some((item) => item.startsWith('index_'))) {
        this.routePath = 'index'
      } else if (segments.some((item) => item.startsWith('ege'))) {
        this.routePath = 'ege-informatika'
      } else {
        // Проверяем, нужно ли удалить первый элемент сегментов
        if (this.localesObject.includes(segments[0])) {
          // Удаляем первый элемент
          segments.shift()
        }
        // Иначе возвращаем путь после локали
        this.routePath = segments.join('/')
      }

      // Проверка значения в консоли
      // console.log('Текущий сегмент пути:', this.routePath);
    },
    updateBaseColor() {
      this.baseColor = colors[this.routePath]
    },
  },
}
