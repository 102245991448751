//
//
//
//
//
//

export default {
  name: 'AnchorText',
  props: {
    text: {
      type: String,
      required: true,
      default: 'Default Text',
    },
    anchor: {
      type: String,
      default: '#',
    },
    wrapperClass: {
      type: String,
      default: '',
    },
  },
}
