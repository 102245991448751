//
//
//
//
//
//
//
//
//
//
//
//

import CtaButton from '@/components/v2/button/CtaButton.vue'

export default {
  name: 'ProgramButton',
  components: {
    CtaButton,
  },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  computed: {
    buttonText() {
      return this.detailData.data.textIndex !== undefined &&
        this.detailData.data.textIndex.length > 0
        ? this.detailData.data.textIndex
        : this.detailData.data.text
    },
  },
}
