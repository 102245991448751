//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SocialMedia from '@/components/v2/nav/SocialMedia.vue'
import AnchorText from '@/components/v2/nav/AnchorText.vue'
import RightArrow from '@/components/v2/span/RightArrow.vue'
import AdapterMaskNumberFooter from '@/components/v2/adapters/MaskNumberFooter.vue'
import LanguageDropdown from '@/components/v2/span/LanguageDropdown.vue'
import { mapState } from 'vuex'

export default {
  name: 'FooterMain',
  components: {
    SocialMedia,
    AnchorText,
    RightArrow,
    AdapterMaskNumberFooter,
    LanguageDropdown,
  },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      inputData: {
        name: '',
        phone: '',
        email: '',
      },
      logoFooter: require('@/assets/img/v2/logoFooter.svg'),
      logoFooterMobile: require('@/assets/img/v2/logoFooterMobile.svg'),

      isDesktop: false,
    }
  },
  computed: {
    ...mapState(['i18n']),
  },
  mounted() {
    this.checkWidth()
    window.addEventListener('resize', this.checkWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWidth)
  },
  methods: {
    checkWidth() {
      this.isDesktop = window.innerWidth > 768
      // console.log('isDesktop: ', this.isDesktop);
    },
    clearInput() {
      this.inputData.name = ''
      this.inputData.phone = ''
      this.inputData.email = ''
    },
    async submitForm() {
      const orderCall = {
        name: this.inputData.name,
        phone: this.inputData.phone,
        email: this.inputData.email,
      }
      // console.log('orderCall: ', orderCall);
      this.$store.dispatch('requestSuccess/showPopUp')
      await this.$store.dispatch('orderCall/fetch', orderCall)

      this.clearInput()
    },
    getOfferLink() {
      if (this.$route.path.includes('/camp')) {
        const offerFile = this.$getTextSetting('campOfferLink')
        if (offerFile?.url) {
          return offerFile?.url
        }
      }
      return this.$getTextMainSetting('offerLink')
    },
  },
}
