//
//
//
//
//

export default {
  name: 'RightArrow',

  props: {
    clasSpan: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '0',
    },
  },
}
