var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"it-goals"},[_c('h2',{staticClass:"it-goals-subject"},[_vm._v("\n    "+_vm._s(_vm.detailData.data.header)+" "),_c('CursorChanger')],1),_vm._v(" "),_c('ScrollWrapperV2',{attrs:{"wrapper-class":"age-cards"}},_vm._l((_vm.ageCardsForShow),function(card,index){return _c('div',{key:card.title,class:{
        'age-card': true,
        first: index === _vm.currentCard,
        fourth: index === 3,
        hidden: card.isHidden,
      },on:{"click":function($event){return _vm.setCard(index)}}},[_c('span',[_vm._v(_vm._s(card.title))]),_vm._v(" "),_c('p',{staticClass:"limited-lines"},[_vm._v("\n        "+_vm._s(card.text)+"\n        "),_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":require(("@/assets/img/v2/ItGoals/" + (card.span))),"alt":"icon"}})])])}),0),_vm._v(" "),_c('ScrollWrapperV2',{attrs:{"wrapper-class":"study-directions"}},_vm._l((_vm.cardsForShow),function(direction){return _c('div',{key:direction.code,staticClass:"study-direction",style:({
        backgroundImage: ("url(" + (require(("@/assets/img/v2/ItGoals/courses/" + (_vm.coursesImg[direction.code] || 'main.png')))) + ")"),
      })},[_c('div',{staticClass:"direction-text"},[_c('p',{staticClass:"direction",domProps:{"innerHTML":_vm._s(direction.childrens_age)}}),_vm._v(" "),_c('h4',{domProps:{"innerHTML":_vm._s(direction.title)}}),_vm._v(" "),_c('p',{staticClass:"direction"},[_vm._v(_vm._s(direction.description))]),_vm._v(" "),_c('a',{on:{"click":function($event){return _vm.goToCourse(direction.url)}}},[_vm._v("\n          "+_vm._s(_vm.detailData.data.textButton)+" >\n        ")])])])}),0),_vm._v(" "),_c('CtaButton',{attrs:{"text":_vm.detailData.data.textButton,"text-path":"#application-form","max-button":true}}),_vm._v(" "),_c('div',{staticClass:"advantages_container"},_vm._l((_vm.detailData.data.benefits),function(benefit,index){return _c('ul',{key:index,staticClass:"advantages"},[_c('li',{staticClass:"advantage"}),_vm._v(" "),_c('p',[_vm._v(_vm._s(benefit))])])}),0),_vm._v(" "),(1 === 2)?_c('div',{staticClass:"as-well-as"},[_c('h3',[_vm._v(_vm._s(_vm.detailData.data.asWellAs.commonTitle))]),_vm._v(" "),_c('div',{staticClass:"as-well-as_block"},_vm._l((_vm.featuredCards),function(item,index){return _c('div',{key:index,staticClass:"as-well-as-item"},[_c('h4',[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(item.text))]),_vm._v(" "),(item.link)?_c('a',{attrs:{"href":item.url}},[_vm._v(_vm._s(item.text.split('\n')[1]))]):_vm._e()])}),0)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }