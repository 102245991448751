//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DetailsInfo from '@/components/v2/pop-up/DetailsInfo.vue'
import ArrowBase from '@/components/v2/span/ArrowBase.vue'

export default {
  name: 'InfoBox',
  components: {
    DetailsInfo,
    ArrowBase,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    iconType: {
      type: String,
      default: 'gift',
      validator: (value) => ['gift', 'holiday'].includes(value),
    },
    popUpText: {
      type: String,
      default: '',
    },
    popUpHeader: {
      type: String,
      default: '',
    },
    classButton: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isPopUp: false,
      isPopUpVisible: false,
      // currentIconIndex: 0,
      // currentIcon: '',
      fading: false,
      // icons: {
      //   gift: [],
      //   holiday: [],
      // },
      iconIntervals: {
        gift: { interval: 1500, pause: 4000 },
        holiday: { interval: 1500, pause: 1500 },
      },
      icons: {
        gift: [
          require('@/assets/img/v2/gift1.svg'),
          require('@/assets/img/v2/gift2.svg'),
        ],
        holiday: [
          require('@/assets/img/v2/holiday1.svg'),
          require('@/assets/img/v2/holiday2.svg'),
          require('@/assets/img/v2/holiday3.svg'),
          require('@/assets/img/v2/holiday4.svg'),
        ],
      },
      currentIconIndex: 0,
    }
  },
  computed: {
    // iconList() {
    //   return this.icons[this.iconType];
    // },
    currentIcon() {
      return this.icons[this.iconType][this.currentIconIndex]
    },
    popUp() {
      return !!this.popUpText
    },
  },

  watch: {
    // iconType() {
    //   this.startIconAnimation();
    // },
  },
  created() {
    if (this.popUpText) {
      this.isPopUp = true
    }
  },
  mounted() {
    // console.log('isPopUp', this.isPopUp);
    // this.preloadIcons();
    // this.startIconAnimation();
    this.updateIconAnimation()
  },
  beforeDestroy() {
    // clearInterval(this.iconInterval);
  },
  methods: {
    togglePopUp() {
      this.isPopUpVisible = !this.isPopUpVisible
    },
    handleClick() {
      // он только срабатывает при закрытии
      if (this.popUp) {
        // console.log('this.popUp: if', this.popUp);
        this.togglePopUp()
      } else {
        // console.log('this.popUp: else', this.popUp);
        this.togglePopUp()
      }
    },

    // preloadIcons() {
    //   const icons = {
    //     gift: ['gift1.svg', 'gift2.svg'],
    //     holiday: ['holiday1.svg', 'holiday2.svg', 'holiday3.svg', 'holiday4.svg'],
    //   };

    //   for (const type in icons) {
    //     this.icons[type] = icons[type].map((icon) => {
    //       const image = new Image();
    //       image.src = require(`@/assets/img/v2/${icon}`);
    //       return image.src;
    //     });
    //   }

    //    this.currentIcon = this.icons[this.iconType][0];
    // },
    // startIconAnimation() {
    //   clearInterval(this.iconInterval);
    //   const { interval, pause } = this.iconIntervals[this.iconType];

    //   const updateIcon = () => {
    //     this.fading = true;
    //     setTimeout(() => {
    //       this.currentIconIndex = (this.currentIconIndex + 1) % this.iconList.length;
    //       this.currentIcon = this.iconList[this.currentIconIndex];
    //       setTimeout(() => {
    //         this.fading = false;
    //       }, 10);
    //     }, 10);
    //   };

    //   this.iconInterval = setInterval(updateIcon, interval + 200);

    //   if (this.iconType === 'gift') {
    //     setInterval(
    //       () => {
    //         setTimeout(() => {
    //           updateIcon();
    //         }, pause);
    //       },
    //       pause + interval + 200
    //     );
    //   }
    // },
    updateIconAnimation() {
      const giftIconList = this.icons.gift
      const holidayIconList = this.icons.holiday

      const generateKeyframes = (iconList, animationName) => {
        const iconUrls = iconList
          .map((icon, index) => {
            const percentage = (index / iconList.length) * 100
            return `${percentage}% { content: url(${icon}); }`
          })
          .join(' ')

        return `
          @keyframes ${animationName} {
            ${iconUrls}
            100% { content: url(${iconList[0]}); }
          }
        `
      }

      const giftKeyframes = generateKeyframes(giftIconList, 'giftIconChange')
      const holidayKeyframes = generateKeyframes(
        holidayIconList,
        'holidayIconChange'
      )

      const iconStyle = `
        .gift::before {
          animation: giftIconChange 4s infinite;
        }
        .holiday::before {
          animation: holidayIconChange 4s infinite;
        }
        .icon::before {
          display: inline-block;
          width: 57px;
          height: 64px;
          content: '';
          background-size: cover;
          @media screen and (max-width: 768px) {
          display: flex;
          width: 44.805px;
          height: 49.146px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          }
        }

        ${giftKeyframes}
        ${holidayKeyframes}
      `

      const styleSheet = document.createElement('style')
      styleSheet.type = 'text/css'
      styleSheet.textContent = iconStyle
      document.head.appendChild(styleSheet)
    },
  },
}
