//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ScrollWrapperV2 from '@/components/v2/hoc/ScrollWrapperV2.vue'
import DynamicTextIconChanger from '@/components/v2/span/CursorChanger.vue'
export default {
  name: 'PlatformExample',
  components: {
    DynamicTextIconChanger,
    ScrollWrapperV2,
  },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      videoWidth: '1160px',
      videoHeight: '640px',
    }
  },
  computed: {
    dynamicStyles() {
      return {
        width: this.videoWidth,
        height: this.videoHeight,
        margin: '0 auto',
        maxWidth: '1160px',
        maxHeight: '640px',
      }
    },
  },
  mounted() {
    this.updateVideoSize()
    window.addEventListener('resize', this.updateVideoSize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateVideoSize)
  },
  methods: {
    updateVideoSize() {
      if (window.innerWidth <= 768) {
        this.videoWidth = '300px'
        this.videoHeight = '301px'
      } else {
        this.videoWidth = '1160px'
        this.videoHeight = '640px'
      }
    },
  },
}
