//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CtaButton from '@/components/v2/button/CtaButton.vue'
import MapContainer from '@/components/v2/card/MapContainer.vue'
import CardAutoSwitch from '@/components/v2/card/CardAutoSwitch.vue'

export default {
  name: 'MapAddresses',
  components: {
    CtaButton,
    MapContainer,
    CardAutoSwitch,
  },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      mapHtml: '',
      blocks: [
        {
          name: 'map',
          label: this.detailData.data.blokNameMap,
        },
        {
          name: 'foto',
          label: this.detailData.data.blokNamePhoto,
        },
      ],
      currentBlock: 'map',
      typeCart: 'g',
      isDesktop: false,
      windowWidth: 0,
    }
  },
  computed: {
    mapPhotos() {
      const images = [
        this.detailData.data.mapPhoto1,
        this.detailData.data.mapPhoto2,
        this.detailData.data.mapPhoto3,
        this.detailData.data.mapPhoto4,
      ]

      return images
        .filter((item) => item[0]?.url !== undefined)
        .map((item) => item[0].url)
    },
    isDisplayBlock() {
      return !this.detailData.data.isDisplay
    },
  },
  mounted() {
    this.mapHtml = this.detailData.data.mapiFrame
    this.updateWindowWidth()
    window.addEventListener('resize', this.updateWindowWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth)
  },
  methods: {
    showBlock(blockName) {
      this.currentBlock = blockName
    },
    updateWindowWidth() {
      this.isDesktop = window.innerWidth > 768
      this.windowWidth = window.innerWidth
    },
  },
}
