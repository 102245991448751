//
//
//
//

export default {
  name: 'CursorChanger',
  data() {
    return {
      cursors: [
        require('@/assets/img/v2/ItGoals/cursor1.svg'),
        require('@/assets/img/v2/ItGoals/cursor2.svg'),
        require('@/assets/img/v2/ItGoals/cursor3.svg'),
        require('@/assets/img/v2/ItGoals/cursor4.svg'),
      ],
    }
  },
  mounted() {
    this.updateCursorAnimation()
  },
  methods: {
    updateCursorAnimation() {
      const cursorUrls = this.cursors
        .map((cursor, index) => {
          const percentage = (index / this.cursors.length) * 100
          return `${percentage}% { background-image: url(${cursor}); }`
        })
        .join(' ')

      const cursorStyle = `
        @keyframes cursorChange {
          ${cursorUrls}
          100% { background-image: url(${this.cursors[0]}); }
        }
        .cursor-changer {
          animation: cursorChange 4s infinite;
          width: 40px;
          height: 40px;
          display: inline-block;
          background-size: cover;
        }
      `

      const styleSheet = document.createElement('style')
      styleSheet.type = 'text/css'
      styleSheet.textContent = cursorStyle
      document.head.appendChild(styleSheet)
    },
  },
}
