//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Cookies',
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      showPopupDetail: false,
      showPopup: false,
      showSettings: false,
      analyticsCookies: false,
      marketingCookies: false,
      toggle: {
        1: false,
        2: false,
        3: false,
      },
    }
  },
  mounted() {
    this.showPopup = !this.getCookie('cookies-accepted')
  },
  methods: {
    categoryModel(key) {
      return key === 'analyticsCookies'
        ? this.analyticsCookies
        : this.marketingCookies
    },
    setCategoryModel(key, value) {
      if (key === 'analyticsCookies') {
        this.analyticsCookies = value
      } else {
        this.marketingCookies = value
      }
    },

    acceptCookies() {
      this.setCookie('cookies-accepted', 'true', 365)
      this.setCookie('analyticsCookies', this.analyticsCookies, 365)
      this.setCookie('marketingCookies', this.marketingCookies, 365)
      this.showPopup = false
    },
    toggleSettings() {
      this.showSettings = true
      this.showPopupDetail = true
    },
    togglePopupDetail() {
      this.showPopupDetail = false
    },
    saveSettings() {
      this.setCookie('analyticsCookies', this.analyticsCookies, 365)
      this.setCookie('marketingCookies', this.marketingCookies, 365)
      this.showPopup = false
    },
    setCookie(name, value, days) {
      if (process.client) {
        let expires = ''
        if (days) {
          const date = new Date()
          date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
          expires = `; expires=${date.toUTCString()}`
        }
        document.cookie = `${name}=${value || ''}${expires}; path=/`
      }
    },
    getCookie(name) {
      if (process.client) {
        const nameEQ = `${name}=`
        const ca = document.cookie.split(';')
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i]
          while (c.charAt(0) === ' ') c = c.substring(1, c.length)
          if (c.indexOf(nameEQ) === 0)
            return c.substring(nameEQ.length, c.length)
        }
      }
      return null
    },
    toggleCategory(index) {
      this.$set(this.toggle, index + 1, !this.toggle[index + 1])
    },
  },
}
