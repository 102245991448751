//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BasePageWrapper from '@/components/v2/wrapper/BasePageWrapper.vue'

export default {
  name: 'MainPage',
  components: {
    BasePageWrapper,
  },
  data() {
    return {
      isDetailPage: false,
    }
  },
  methods: {},
}
