export default {
  methods: {
    checkElementVisibility(refName) {
      const element = this.$refs[refName]
      if (!element) {
        // console.warn(`Element with ref "${refName}" not found.`);
        return false
      }
      const rect = element.getBoundingClientRect()
      const viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight
      )
      // Проверка, виден ли элемент
      const isVisible = rect.bottom > 0 && rect.top < viewHeight
      return isVisible
    },
  },
}
