//
//
//
//
//
//
//
//
//

export default {
  name: 'MapContainer',
  props: {
    mapHtml: {
      type: String,
      required: true,
    },
    wrapperClass: {
      type: String,
      default: '',
    },
    borderRadius: {
      type: Number,
      default: 20,
    },
  },
  watch: {
    borderRadius() {
      // console.log('Updated borderRadius: ', this.borderRadius);
      this.applyIframeStyles()
    },
  },
  mounted() {
    // console.log('borderRadius: ', this.borderRadius);
    this.applyIframeStyles()
  },
  updated() {
    this.applyIframeStyles()
  },
  methods: {
    applyIframeStyles() {
      this.$nextTick(() => {
        const iframe = this.$refs.mapContainer?.querySelector('iframe')
        if (iframe) {
          iframe.style.width = '100%'
          iframe.style.height = '100%'
          iframe.style.borderRadius = `${this.borderRadius}px`
        }
      })
    },
  },
}
