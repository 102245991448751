var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{ref:"basePageWrapper",class:[
    'page',
    _vm.pageName,
    {
      desktop: _vm.viewportWidth > 768,
      mobile: _vm.viewportWidth <= 768,
      detail: _vm.isDetailPage,
    } ],style:({
    '--base-color': _vm.hexToRgba(_vm.baseColor),
    '--base-left-lower': _vm.backgroundColor.leftLower,
    '--base-upper-right': _vm.backgroundColor.upperRight,
    '--viewport-width': (_vm.viewportWidth + "px"),
  }),attrs:{"id":("page page__" + _vm.pageName)}},[_vm._l((_vm.componentOrder),function(componentName,index){return _c(componentName,{key:index,tag:"component",attrs:{"detail-data":_vm.getDetailDataForComponent(componentName)}})}),_vm._v(" "),_c('ProgramButton',{attrs:{"detail-data":_vm.generateDetailData('ProgramButton')}}),_vm._v(" "),_c('CallMeBack',{attrs:{"detail-data":_vm.generateDetailData('CallMeBack')}}),_vm._v(" "),_c('CookiePopup',{attrs:{"detail-data":_vm.generateDetailData('CookiePopup')}}),_vm._v(" "),_c('RequestSuccess',{attrs:{"detail-data":_vm.generateDetailData('RequestSuccess')}}),_vm._v(" "),_c('SelectYourArea',{attrs:{"detail-data":_vm.generateDetailData(''),"is-show-pop-up":_vm.isPopUpVisible},on:{"closePopUp":_vm.handleClickPopUp}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }