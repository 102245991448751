//
//
//
//
//
//
//

export default {
  name: 'PlatformLogo',
  props: {},
  data() {
    return {
      icons: [
        require('@/assets/img/v2/PlatformExample/PlatformLogo1.svg'),
        require('@/assets/img/v2/PlatformExample/PlatformLogo2.svg'),
        require('@/assets/img/v2/PlatformExample/PlatformLogo3.svg'),
        require('@/assets/img/v2/PlatformExample/PlatformLogo4.svg'),
      ],
    }
  },
}
