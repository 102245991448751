//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DetailsInfo',
  props: {
    text: {
      type: String,
      default: '',
    },
    header: {
      type: String,
      default: '',
    },
    isVis: {
      type: Boolean,
      default: false,
    },
    btn: {
      type: String,
      default: '',
    },
  },

  data() {
    return {}
  },

  watch: {},

  methods: {
    closePopUp() {
      this.$emit('close')
    },
  },
}
