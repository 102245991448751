//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BtwSection',
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {}
  },
}
