//
//
//
//
//
//
//
//

// v2 store
// import { mapActions } from 'vuex'
import { popUpMethods } from '~/plugins/popUp.js'
export default {
  name: 'CallbackButton',
  props: {
    dataBtn: {
      type: Object,
      default: () => ({
        data: {},
      }),
    },
  },
  data() {
    return {}
  },
  computed: {
    // isVisible() {
    //   return popUpStore.isVisible
    // },
  },

  methods: {
    showPopUp() {
      // this.$store.dispatch('popUp/showPopUp')
      popUpMethods.showPopUp()
    },
    // v2 store
    // ...mapActions('popUp', ['showPopUp', 'hidePopUp']),
    // showPopUp() {
    //   this.showPopUp()
    // },
  },
}
