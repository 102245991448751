//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex'
export default {
  name: 'CityDropdown',
  props: {
    wrapperClass: {
      type: String,
      default: '',
    },
    isFixedNav: {
      type: Boolean,
      default: false,
    },
    cityData: {
      type: Object,
      default: () => ({
        placeholder: 'Поиск города...',
        headerPop: 'Выберите город',
      }),
    },
  },
  data() {
    return {
      dropdownActive: false,
      searchQuery: '',
      // selectedCity: this.cityData.base,
      selectedCity: '', // выбранный город
      loadedCityData: {
        base: 'Москва',
        cities: [],
      },
    }
  },
  computed: {
    ...mapState('landings', {
      currentDomainData: (state) => state.currentDomainData, // Текущий домен (город)
    }),
    ...mapGetters({
      domains: 'landings/domains', // Получаем список всех городов (доменов)
    }),
    formattedCities() {
      // Преобразуем список доменов в нужный формат для dropdown
      return {
        base: this.currentDomainData.title || 'Москва', // Текущий выбранный город
        cities: this.domains.map((domain) => ({
          name: domain.title, // Название города
          value: domain.siteUrl, // URL как значение
        })),
      }
    },
    filteredCities() {
      if (!this.searchQuery) {
        return this.loadedCityData.cities
      }
      const query = this.searchQuery.trim().toLowerCase()
      return this.loadedCityData.cities.filter((city) =>
        city.name.toLowerCase().includes(query)
      )
    },
  },
  mounted() {
    // Получаем список городов при монтировании компонента
    this.fetchCities()
  },
  methods: {
    toggleDropdown() {
      if (this.dropdownActive === true) {
        this.dropdownActive = false
        this.closedPopUpCity()
      } else {
        this.dropdownActive = true
        this.openedPopUpCity()
      }
    },
    inactiveToggleDropdown() {
      this.dropdownActive = false
      this.closedPopUpCity()
    },
    selectCity(city) {
      this.selectedCity = city.name
      this.dropdownActive = false // Закрыть выпадающее меню после выбора
      this.closedPopUpCity()
    },
    closedPopUpCity() {
      this.$emit('closedPopUpCity')
    },
    openedPopUpCity() {
      this.$emit('openedPopUpCity')
    },
    fetchCities() {
      try {
        // Используем данные из formattedCities
        this.loadedCityData = this.formattedCities // Копируем данные в loadedCityData
        this.selectedCity = this.loadedCityData.base // Устанавливаем базовый город как выбранный
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Ошибка при получении городов:', error)
      }
    },
  },
}
