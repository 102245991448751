//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// v2 store
// import { mapGetters, mapActions } from 'vuex'
// import { popUpStore } from '~/plugins/popUp.js'
import { popUpStore, popUpMethods } from '~/plugins/popUp.js'
import AdapterMaskNumberPopUp from '~/components/v2/adapters/MaskNumberPopUp.vue'
export default {
  name: 'CallMeBack',
  components: { AdapterMaskNumberPopUp },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      formData: {
        parentName: '',
        phone: '',
        newsletter: true,
      },
    }
  },

  computed: {
    isVisible() {
      return popUpStore.isVisible
    },
    // isVisible() {
    //   return this.$store.getters['popUp/isPopUpVisible']
    // },
    // Альтернативный вариант с использованием mapGetters
    // ...mapGetters('popUp', ['isPopUpVisible']),
    // isVisible() {
    //   return this.isPopUpVisible;
    // },
    // ...mapGetters('popUp', {
    //   isVisible: 'isPopUpVisible', // mapGetters возвращает computed-свойства
    // }),
  },
  mounted() {
    // console.log('detailData.data', this.detailData.data)
  },
  methods: {
    clearInput() {
      this.formData.parentName = ''
      this.formData.phone = ''
      this.formData.newsletter = true
    },
    hidePopUpCl() {
      // this.$store.dispatch('popUp/hidePopUp')
      popUpMethods.hidePopUp()
    },
    // v2 store
    // ...mapActions('popUp', ['showPopUp', 'hidePopUp']),
    // hidePopUpCl() {
    //   this.hidePopUp()
    // },
    async submitForm() {
      const orderCall = {
        name: this.formData.parentName,
        phone: this.formData.phone,
      }
      // console.log('orderCall: ', orderCall);

      await this.$store.dispatch('orderCall/fetch', orderCall)
      this.clearInput()
      this.hidePopUpCl()
    },
  },
}
