//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import CtaButton from '@/components/v2/button/CtaButton.vue'
import CursorChanger from '@/components/v2/span/CursorChanger.vue'
import ScrollWrapperV2 from '@/components/v2/hoc/ScrollWrapperV2.vue'
export default {
  name: 'ItGoals',
  components: {
    CtaButton,
    CursorChanger,
    ScrollWrapperV2,
  },

  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      // через scss можно менять фото каждых 1 секунду
      cursor1: require('@/assets/img/v2/ItGoals/cursor1.svg'),
      cursor2: require('@/assets/img/v2/ItGoals/cursor2.svg'),
      cursor3: require('@/assets/img/v2/ItGoals/cursor3.svg'),
      cursor4: require('@/assets/img/v2/ItGoals/cursor4.svg'),
      coursesImg: {
        scratch: 'scratch.png',
        frontend: 'frontend.png',
        game_design: 'game_design.png',
        graphic_design: 'graphic_design.png',
        main: 'main.png',
        python: 'python.png',
        unity: 'unity.png',
        videoblogging: 'videoblogging.png',
        'web-razrabotka': 'web-razrabotka.png',
        computer_literacy: 'computer_literacy.png',
        programming_basics: 'programming_basics.png',
        predprinimatelstvo: 'predprinimatelstvo.png',
      },
      currentCard: 0,
      // основные данные
    }
  },
  computed: {
    ...mapState(['i18n']),
    activeCards() {
      if (!this.detailData?.data?.studyDirectionsFoto) return []
      return this.detailData.data.studyDirectionsFoto.filter(
        (el) => el.is_active
      )
    },
    cardsForShow() {
      if (!this.detailData?.data?.ageCards?.[this.currentCard]?.courses)
        return []
      return this.activeCards.filter((el) =>
        this.detailData?.data?.ageCards?.[this.currentCard]?.courses.includes(
          el?.code
        )
      )
    },
    ageCardsForShow() {
      return this.detailData?.data?.ageCards.map((el) => ({
        ...el,
        isHidden: !this.activeCards?.filter((elem) =>
          el?.courses.includes(elem?.code)
        )?.length,
      }))
    },
    featuredCards() {
      return [
        {
          title: 'ЕГЭ',
          text: 'описание',
        },
        {
          title: 'Предпринимательство',
          text: 'описание',
          url: '#url',
        },
      ]
    },
  },
  mounted() {
    this.setCard(this.ageCardsForShow.map((el) => !el.isHidden).indexOf(true))
  },
  methods: {
    setCard(card) {
      this.currentCard = card
    },
    goToCourse(url) {
      window.location.href = this.localePath(url, this.i18n.locale)
    },
  },
}
