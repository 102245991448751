const keyPage = [
  'main',
  'egeInformatika',
  'predprinimatelstvo',
  'osnovyProgrammirovaniya',
  'python',
  'unity',
  'webRazrabotka',
  'gameDesign',
  'graphicDesign',
  'videoblogging',
  'scratch',
  'computernayaGramotnost',
  'frontend',
]
// http://landing.algo.loc/api/init?url=http://landing.algo.loc/ru/ege-informatika
const listAllKeysFromBackup = ['', 'getTextSetting', 'getTextMainSetting']
/**
 * @typedef {Object} KeyValue
 * @property {number} 0 - Число
 * @property {string} 1 - Строка
 */

/**
 * @typedef {Object} PartOf
 * @property {KeyValue} anchor
 * @property {KeyValue} text
 */

/**
 * @typedef {Object} Tel
 * @property {KeyValue} href
 * @property {KeyValue} text
 */

/**
 * @typedef {Object} Mail
 * @property {KeyValue} text
 * @property {KeyValue} anchor
 */

/**
 * @typedef {Object} CallMeBack
 * @property {KeyValue} base
 * @property {KeyValue} header
 * @property {KeyValue} text
 * @property {KeyValue} name
 * @property {KeyValue} phone
 * @property {KeyValue} btn
 * @property {KeyValue} agreement1
 * @property {KeyValue} agreement2
 */

/**
 * @typedef {Object} City
 * @property {KeyValue} name
 * @property {KeyValue} value
 */

/**
 * @typedef {Object} CityDropdown
 * @property {KeyValue} placeholder
 * @property {KeyValue} headerPop
 * @property {KeyValue} span
 * @property {KeyValue} base
 * @property {City[]} cities
 */

/**
 * @typedef {Object} FirstSteps
 * @property {Object} dataC2
 * @property {KeyValue} header
 * @property {KeyValue} p
 * @property {Array} listItems
 * @property {KeyValue} buttonText
 * @property {Object[]} infoBoxDatas
 * @property {KeyValue} textButton
 * @property {KeyValue} iconType
 * @property {KeyValue} classButton
 * @property {KeyValue} popUpHeader
 * @property {KeyValue} popUpText
 */

/**
 * @typedef {Object} ProgramButton
 * @property {KeyValue} text
 */

/**
 * @typedef {Object} CardData
 * @property {KeyValue} title
 * @property {KeyValue} text
 */

/**
 * @typedef {Object} WhatWeOffer
 * @property {KeyValue} header
 * @property {CardData[]} cardData
 */

/**
 * @typedef {Object} WillDo
 * @property {KeyValue} header
 * @property {Object[]} cardData
 * @property {KeyValue} logo
 * @property {KeyValue} text
 */

/**
 * @typedef {Object} Teacher
 * @property {KeyValue} foto
 * @property {KeyValue} tema
 * @property {KeyValue} nickname
 * @property {KeyValue} experience
 * @property {KeyValue} goal
 */

/**
 * @typedef {Object} Teachers
 * @property {KeyValue} header
 * @property {Teacher[]} professorsDatas
 */

/**
 * @typedef {Object} Result
 * @property {KeyValue} header
 * @property {KeyValue} text
 * @property {KeyValue} textPc
 * @property {KeyValue[]} img
 */

/**
 * @typedef {Object} FormatOfClasses
 * @property {KeyValue} header
 * @property {KeyValue[]} text
 */

/**
 * @typedef {Object} ApplicationForm
 * @property {KeyValue} formTitle
 * @property {KeyValue} formDescription
 * @property {KeyValue} parentNamePlaceholder
 * @property {KeyValue} phonePlaceholder
 * @property {KeyValue} emailPlaceholder
 * @property {KeyValue} submitButtonText
 * @property {KeyValue} newsletterText
 * @property {KeyValue} privacyPolicyText
 */

/**
 * @typedef {Object} FAQItem
 * @property {KeyValue} header
 * @property {KeyValue} text
 */

/**
 * @typedef {Object} FAQ
 * @property {KeyValue} header
 * @property {FAQItem[]} faqData
 */

/**
 * @typedef {Object} FooterContact
 * @property {Tel} tel
 * @property {Mail} mail
 * @property {KeyValue} label
 */

/**
 * @typedef {Object} FooterCity
 * @property {KeyValue} text
 */

/**
 * @typedef {Object} FooterForm
 * @property {KeyValue} namePlaceholder
 * @property {KeyValue} phonePlaceholder
 * @property {KeyValue} emailPlaceholder
 * @property {KeyValue} submitButton
 */

/**
 * @typedef {Object} FooterLinks
 * @property {KeyValue} text
 * @property {KeyValue} href
 */

/**
 * @typedef {Object} FooterMain
 * @property {FooterContact} contact
 * @property {FooterCity} city
 * @property {FooterForm} form
 * @property {FooterLinks[]} links
 * @property {KeyValue} footerBottomText
 */

/**
 * @typedef {Object} CookiePopupItem
 * @property {KeyValue} key
 * @property {KeyValue} subject
 * @property {KeyValue} caption
 * @property {KeyValue} text
 */

/**
 * @typedef {Object} CookiePopup
 * @property {KeyValue} baseText
 * @property {KeyValue} btOk
 * @property {KeyValue} btInfo
 * @property {KeyValue} header
 * @property {KeyValue} p
 * @property {CookiePopupItem[]} catch
 * @property {KeyValue} allowedTo
 * @property {KeyValue} isProhibited
 * @property {KeyValue} btSave
 */

/**
 * @typedef {Object} LocationPrompt
 * @property {KeyValue} text1
 * @property {KeyValue} city
 * @property {KeyValue} text2
 * @property {KeyValue} yes
 * @property {KeyValue} no
 */

/**
 * @typedef {Object} RequestSuccess
 * @property {KeyValue} header
 * @property {KeyValue} text
 * @property {KeyValue} btn
 */

/**
 * @typedef {Object} TrustSectionCard
 * @property {KeyValue} title
 * @property {KeyValue} description
 */

/**
 * @typedef {Object} TrustSection
 * @property {KeyValue} header
 * @property {TrustSectionCard[]} cards
 */

/**
 * @typedef {Object} AgeCard
 * @property {KeyValue} title
 * @property {KeyValue} text
 * @property {KeyValue} span
 */

/**
 * @typedef {Object} StudyDirectionsFoto
 * @property {KeyValue} story
 * @property {KeyValue} title
 * @property {KeyValue} text
 * @property {KeyValue} result
 * @property {KeyValue} img
 */

/**
 * @typedef {Object} ItGoals
 * @property {KeyValue} header
 * @property {AgeCard[]} ageCards
 * @property {StudyDirectionsFoto[]} studyDirectionsFoto
 * @property {KeyValue} textButton
 * @property {KeyValue[]} benefits
 * @property {Object} asWellAs
 * @property {KeyValue} commonTitle
 * @property {Object[]} block
 * @property {KeyValue} title
 * @property {KeyValue} text
 * @property {KeyValue} link
 */

/**
 * @typedef {Object} LongTermLearning
 * @property {KeyValue} header
 * @property {Object[]} teachData
 * @property {KeyValue} logo
 * @property {KeyValue} text
 */

/**
 * @typedef {Object} Platform
 * @property {Object[]} platformFeatures
 * @property {KeyValue[]} textOptions
 * @property {KeyValue} youtube
 */

/**
 * @typedef {Object} BtwSectionCompany
 * @property {number} id
 * @property {KeyValue} logo
 * @property {KeyValue} alt
 * @property {KeyValue} text
 */

/**
 * @typedef {Object} BtwSection
 * @property {KeyValue} header
 * @property {BtwSectionCompany[]} companies
 */

/**
 * @typedef {Object} TestimonialsCustomer
 * @property {KeyValue} text
 * @property {KeyValue} nickname
 * @property {KeyValue} hrefText
 * @property {KeyValue} href
 * @property {KeyValue} foto
 */

/**
 * @typedef {Object} TestimonialsCustomers
 * @property {KeyValue} header
 * @property {TestimonialsCustomer[]} commentsDatas
 */

/**
 * @typedef {Object} MapAddresses
 * @property {Object} mapContext
 * @property {KeyValue} header
 * @property {KeyValue} callToAction
 * @property {KeyValue} time
 * @property {KeyValue} buttonText
 * @property {KeyValue[]} addresses
 * @property {KeyValue[]} MapForo
 * @property {KeyValue[]} blocks
 * @property {KeyValue} mapiFrame
 * @property {KeyValue} mapiFrame2
 */

/**
 * @type {Object} allKeysOfTexts
 * @property {KeyValue[]} routePath
 * @property {Object} Header
 * @property {PartOf[]} Header.partOf
 * @property {Tel} Header.tel
 * @property {Mail} Header.mail
 * @property {CallMeBack} Header.CallMeBack
 * @property {CityDropdown} Header.CityDropdown
 * @property {FirstSteps} FirstSteps
 * @property {ProgramButton} ProgramButton
 * @property {WhatWeOffer} WhatWeOffer
 * @property {WillDo} WillDo
 * @property {Teachers} Teachers
 * @property {Result} Result
 * @property {FormatOfClasses} FormatOfClasses
 * @property {ApplicationForm} ApplicationForm
 * @property {FAQ} FAQ
 * @property {FooterMain} FooterMain
 * @property {CookiePopup} CookiePopup
 * @property {LocationPrompt} LocationPrompt
 * @property {RequestSuccess} RequestSuccess
 * @property {TrustSection} TrustSection
 * @property {ItGoals} ItGoals
 * @property {LongTermLearning} LongTermLearning
 * @property {Platform} Platform
 * @property {BtwSection} BtwSection
 * @property {TestimonialsCustomers} TestimonialsCustomers
 * @property {MapAddresses} MapAddresses
 */
const allKeysOfTexts = {
  routePath: [0, ''],
  Header: {
    partOf: [
      { anchor: [0, ''], text: [2, 'menuFieldAllCourses'] },
      { anchor: [0, ''], text: [2, 'menuFieldLearning'] },
      { anchor: [0, ''], text: [0, ''] },
      { anchor: [0, ''], text: [0, ''] },
    ],
    tel: {
      href: [2, 'branchPhoneCanonical'],
      text: [2, 'branchPhone'],
    },
    mail: {
      text: [2, 'branchEmail'],
      anchor: [2, 'branchEmail'],
    },
    socials: [
      { href: [2, 'vk_url'], logo: [0, ''] },
      { href: [2, 'ws_url'], logo: [0, ''] },
      { href: [2, 'tg_url'], logo: [0, ''] },
    ],
    CityDropdown: {
      placeholder: [0, ''],
      headerPop: [0, ''],
      span: [0, ''],
      base: [0, ''],
      cities: [
        { name: [0, ''], value: [0, ''] },
        { name: [0, ''], value: [0, ''] },
        { name: [0, ''], value: [0, ''] },
        { name: [0, ''], value: [0, ''] },
        { name: [0, ''], value: [0, ''] },
        { name: [0, ''], value: [0, ''] },
      ],
    },
    textBaseCallMeBack: [0, ''],
  },
  CallMeBack: {
    base: [0, ''],
    header: [0, ''],
    text: [0, ''],
    name: [0, ''],
    phone: [0, ''],
    btn: [0, ''],
    agreement1: [0, ''],
    agreement2: [0, ''],
  },
  FirstSteps: {
    dataIndex: {
      header: [1, 'v2IndexFirstStepTitle'],
      p: [1, 'v2IndexFirstStepDescriptionBeginning'],
      listItems: [],
      buttonText: [1, 'v2IndexFirstStepButtonText'],
    },
    dataC2: {
      header: [1, 'v2IndexProgramTitle'],
      p: [1, 'v2IndexProgramDescription'],
      listItems: [],
      buttonText: [1, 'v2IndexProgramButtonText'],
    },
    infoBoxDatas: [
      {
        textButton: [0, ''],
        iconType: [0, ''],
        classButton: [0, ''],
        popUpHeader: [0, ''],
        popUpText: [0, ''],
      },
      {
        textButton: [0, ''],
        iconType: [0, ''],
        classButton: [0, ''],
        popUpHeader: [0, ''],
        popUpText: [0, ''],
      },
    ],
  },
  ProgramButton: {
    text: [1, 'v2IndexProgramButtonText'],
    textIndex: [1, 'v2IndexFirstStepButtonText'],
  },
  WhatWeOffer: {
    header: [1, 'v2IndexPreparingTitle'],
    cardData: [
      {
        title: [1, 'v2IndexPreparingBlock1Title'],
        text: [1, 'v2IndexPreparingBlock1Description'],
      },
      {
        title: [1, 'v2IndexPreparingBlock2Title'],
        text: [1, 'v2IndexPreparingBlock2Description'],
      },
      {
        title: [1, 'v2IndexPreparingBlock3Title'],
        text: [1, 'v2IndexPreparingBlock3Description'],
      },
    ],
  },
  WillDo: {
    header: [1, 'v2IndexHowWeWorkTitle'],
    cardData: [
      {
        logo: [0, ''],
        text: [1, 'v2IndexHowWeWorkStep1Description'],
      },
      {
        logo: [0, ''],
        text: [1, 'v2IndexHowWeWorkStep2Description'],
      },
      {
        logo: [0, ''],
        text: [1, 'v2IndexHowWeWorkStep3Description'],
      },
      {
        logo: [0, ''],
        text: [1, 'v2IndexHowWeWorkStep4Description'],
      },
    ],
  },
  Teachers: {
    header: [1, 'v2IndexTeachersTitle'],
    isDisplay: [1, 'v2IndexHideTeachers'],
    professorsDatas: [
      {
        foto: [0, ''],
        tema: [1, 'v2IndexTeachers1Title'],
        nickname: [1, 'v2IndexTeachers1Subtitle'],
        experience: [1, 'v2IndexTeachers1Description'],
      },
      {
        foto: [0, ''],
        goal: [0, ''],
      },
      {
        foto: [0, ''],
        tema: [0, ''],
        nickname: [1, 'v2IndexTeachers2Title'],
        experience: [1, 'v2IndexTeachers2Description'],
      },
      {
        foto: [0, ''],
        goal: [0, ''],
      },
      // {
      //   foto: [0, ''],
      //   tema: [0, ''],
      //   nickname: [1, 'v2IndexTeachers3Title'],
      //   experience: [1, 'v2IndexTeachers3Description'],
      // },
      // {
      //   foto: [0, ''],
      //   goal: [0, ''],
      // },
    ],
  },
  Result: {
    header: [1, 'v2IndexToolsTitle'],
    text: [0, ''],
    textPc: [1, 'v2IndexToolsDescription'],
    img: [
      [0, ''],
      [0, ''],
      [0, ''],
    ],
  },
  FormatOfClasses: {
    header: [1, 'v2IndexLessonFormatsTitle'],
    text: [
      [1, 'v2IndexLessonFormat1'],
      [1, 'v2IndexLessonFormat2'],
      [1, 'v2IndexLessonFormat3'],
      [1, 'v2IndexLessonFormat4'],
    ],
  },
  ApplicationForm: {
    formTitle: [1, 'v2IndexLeadFormTitle'],
    formDescription: [1, 'v2IndexLeadFormSubtitle'],
    parentNamePlaceholder: [0, ''],
    phonePlaceholder: [0, ''],
    emailPlaceholder: [0, ''],
    submitButtonText: [1, 'v2IndexLeadFormButtonText'],
    newsletterText: [0, ''],
    privacyPolicyText: [0, ''],
    crmPipeline: [0, ''],
    crmStatus: [0, ''],
    crmTags: [0, ''],
  },
  FAQ: {
    header: [1, 'v2IndexFaqTitle'],
    faqData: [1, 'v2IndexFaq', 'bullets'],
  },
  FooterMain: {
    contact: {
      tel: {
        text: [2, 'branchPhone'],
        anchor: [2, 'branchPhoneCanonical'],
      },
      mail: {
        text: [2, 'branchEmail'],
        anchor: [2, 'branchEmail'],
      },
      label: {
        text: [0, ''],
      },
    },
    city: {
      text: [0, ''],
    },
    socials: [
      { href: [2, 'vk_url'], logo: [0, ''] },
      { href: [2, 'ws_url'], logo: [0, ''] },
      { href: [2, 'tg_url'], logo: [0, ''] },
    ],
    form: {
      namePlaceholder: [0, ''],
      phonePlaceholder: [0, ''],
      emailPlaceholder: [0, ''],
      submitButton: [0, ''],
    },
    links: {
      privacy: {
        text: [2, 'footerPrivacyLinkText'],
      },
      offerLink: {
        text: [2, 'footerMenuOfferLinkText'],
        href: [2, 'offerLink'],
      },
      licenseDetails: { text: [2, 'title_license_details'] },
      linkTextExtractRegisterUrl: {
        text: [2, 'link_text_extract_register_url'],
        href: [2, 'licence_grant_notice_url'],
      },
      linkTextLicenceGrantNoticeUrl: {
        text: [2, 'link_text_licence_grant_notice_url'],
        href: [2, 'extract_register_url'],
      },
      footerOfficialInfoLinkText: {
        text: [2, 'footerOfficialInfoLinkText'],
      },
    },
    footerBottomText: [0, ''],
  },
  CookiePopup: {
    baseText: [0, ''],
    btOk: [0, ''],
    btInfo: [0, ''],
    header: [0, ''],
    p: [0, ''],
    catch: [
      {
        key: [0, ''],
        subject: [0, ''],
        caption: [0, ''],
        text: [0, ''],
      },
      {
        key: [0, ''],
        subject: [0, ''],
        caption: [0, ''],
        text: [0, ''],
      },
      {
        key: [0, ''],
        subject: [0, ''],
        caption: [0, ''],
        text: [0, ''],
      },
    ],
    allowedTo: [0, ''],
    isProhibited: [0, ''],
    btSave: [0, ''],
  },
  LocationPrompt: {
    text1: [0, ''],
    city: [0, ''],
    text2: [0, ''],
    yes: [0, ''],
    no: [0, ''],
  },
  RequestSuccess: {
    header: [0, ''],
    text: [0, ''],
    btn: [0, ''],
  },
  // main только
  TrustSection: {
    header: [1, 'v2IndexAchievementTitle'],
    achievementStudents: [1, 'v2IndexAchievementStudents'],
    achievementStudentsDescription: [
      1,
      'v2IndexAchievementStudentsDescription',
    ],
    achievementCountries: [1, 'v2IndexAchievementCountries'],
    achievementCountriesDescription: [
      1,
      'v2IndexAchievementCountriesDescription',
    ],
    achievementCities: [1, 'v2IndexAchievementCities'],
    achievementCitiesDescription: [1, 'v2IndexAchievementCitiesDescription'],
    cards: [
      {
        title: [1, 'v2IndexAchievementParentsRating'],
        description: [1, 'v2IndexAchievementParentsRatingDescription'],
      },
      {
        title: [1, 'v2IndexAchievementParentsRating'],
        description: [1, 'v2IndexAchievementParentsRatingDescription'],
      },
      {
        title: [1, 'v2IndexAchievementTopTitle'],
        description: [1, 'v2IndexAchievementTopDescription'],
      },
    ],
  },
  ItGoals: {
    header: [1, 'v2IndexITCourseTitle'],
    ageCards: [
      {
        title: [1, 'v2IndexITCourseGroup1Age'],
        text: [1, 'v2IndexITCourseGroup1Name'],
        span: [0, ''],
        courses: [0, ''],
      },
      {
        title: [1, 'v2IndexITCourseGroup2Age'],
        text: [1, 'v2IndexITCourseGroup2Name'],
        span: [0, ''],
        courses: [0, ''],
      },
      {
        title: [1, 'v2IndexITCourseGroup3Age'],
        text: [1, 'v2IndexITCourseGroup3Name'],
        span: [0, ''],
        courses: [0, ''],
      },
      {
        title: [1, 'v2IndexITCourseGroup4Age'],
        text: [1, 'v2IndexITCourseGroup4Name'],
        span: [0, ''],
        courses: [0, ''],
      },
      {
        title: [1, 'v2IndexITCourseGroup5Age'],
        text: [1, 'v2IndexITCourseGroup5Name'],
        span: [0, ''],
        courses: [0, ''],
      },
    ],
    studyDirectionsFoto: [1, '_courseCards', 'bullets'],
    textButton: [1, 'v2IndexITDetailsButtonText'],
    benefits: [
      [1, 'v2IndexITMiniDescription1'],
      [1, 'v2IndexITMiniDescription2'],
      [1, 'v2IndexITMiniDescription3'],
    ],
    asWellAs: {
      commonTitle: [1, 'v2IndexITAndAlso'],
      block: [
        {
          title: [0, ''],
          text: [0, ''],
        },
        {
          title: [0, ''],
          text: [0, ''],
          link: [0, ''],
        },
      ],
    },
  },
  LongTermLearning: {
    header: [1, 'v2IndexPictureTitle'],
    teachData: [
      {
        logo: [1, 'v2IndexPicture1', 'bullets'],
        text: [1, 'v2IndexPicture1Description'],
      },
      {
        logo: [1, 'v2IndexPicture2', 'bullets'],
        text: [1, 'v2IndexPicture2Description'],
      },
      {
        logo: [1, 'v2IndexPicture3', 'bullets'],
        text: [1, 'v2IndexPicture3Description'],
      },
      {
        logo: [1, 'v2IndexPicture4', 'bullets'],
        text: [1, 'v2IndexPicture4Description'],
      },
    ],
  },
  Platform: {
    title: [1, 'v2IndexAboutTitle'],
    platformFeatures: [
      {
        text: [1, 'v2IndexAboutMiniDescription1'],
      },
      {
        text: [1, 'v2IndexAboutMiniDescription2'],
      },
      { text: [1, 'v2IndexAboutMiniDescription3'] },
      { text: [1, 'v2IndexAboutMiniDescription4'] },
    ],
    textOptions: [
      [1, 'v2IndexAboutTitleLastWord1'],
      [1, 'v2IndexAboutTitleLastWord2'],
      [1, 'v2IndexAboutTitleLastWord3'],
      [1, 'v2IndexAboutTitleLastWord4'],
    ],
    youtube: [1, 'v2IndexAboutVideoUrl'],
  },
  BtwSection: {
    header: [1, 'v2IndexCompaniesTitle'],
    companies: [1, 'press', 'bullets'],
  },
  TestimonialsCustomers: {
    isDisplay: [1, 'v2IndexHideReviews'],
    header: [1, 'v2IndexReviewsTitle'],
    commentsDatas: [1, 'v2IndexReviews', 'bullets'],
  },
  MapAddresses: {
    isDisplay: [1, 'v2IndexHideMap'],
    header: [1, 'v2IndexMapTitle'],
    callToAction: [1, 'v2IndexMapSubtitle'],
    time: [1, 'v2IndexMapDescription'],
    buttonText: [1, 'v2IndexMapButtonText'],
    venues: [1, 'venues', 'venues'],
    mapPhoto1: [1, 'v2IndexMapVenuePhoto1', 'bullets'],
    mapPhoto2: [1, 'v2IndexMapVenuePhoto2', 'bullets'],
    mapPhoto3: [1, 'v2IndexMapVenuePhoto3', 'bullets'],
    mapPhoto4: [1, 'v2IndexMapVenuePhoto4', 'bullets'],
    blokNamePhoto: [0, ''],
    blokNameMap: [0, ''],
    mapiFrame: [1, 'yandexMapIframe'],
    mapiFrame2: [0, ''],
  },
}

export { allKeysOfTexts, keyPage, listAllKeysFromBackup }
export default allKeysOfTexts
