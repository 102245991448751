//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SocialMedia',
  props: {
    wrapperClass: {
      type: String,
      default: '',
    },
    location: {
      type: String,
      default: 'header',
    },
    socials: {
      type: Array,
      default: () => [
        { href: '', logo: '' },
        { href: '', logo: '' },
        { href: '', logo: '' },
      ],
      validator(value) {
        return value.every(
          (item) =>
            typeof item.href === 'string' && typeof item.logo === 'string'
        )
      },
    },
  },
  data() {
    return {}
  },
  computed: {
    processedSocials() {
      if (!this.socials || !Array.isArray(this.socials)) {
        return []
      }
      return this.socials
        .map((social) => ({
          ...social,
          logo: this.getLogoPath(social.logo),
        }))
        .filter((social) => social.href.length > 0)
    },
  },
  methods: {
    getLogoPath(logoName) {
      let logos = {
        vk: require('@/assets/img/v2/components/header/vk.svg'),
        wa: require('@/assets/img/v2/components/header/wa.svg'),
        tg: require('@/assets/img/v2/components/header/tg.svg'),
      }
      if (this.location === 'header') {
        logos = {
          vk: require('@/assets/img/v2/components/header/vk.svg'),
          wa: require('@/assets/img/v2/components/header/wa.svg'),
          tg: require('@/assets/img/v2/components/header/tg.svg'),
        }
      } else if (this.location === 'footer') {
        logos = {
          vk: require('@/assets/img/v2/components/footer/vk.svg'),
          wa: require('@/assets/img/v2/components/footer/wa.svg'),
          tg: require('@/assets/img/v2/components/footer/tg.svg'),
        }
      }
      return logos[logoName]
    },
  },
}
